import React, { useState, useEffect, useRef } from 'react';

import moment from 'moment-timezone';
import alarmSound from './alarm.wav';

import './App.css';
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap" />

function App() {
  const [eventName, setEventName] = useState('');
  const [backIn, setBackIn] = useState('');
  const [time, setTime] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [timer, setTimer] = useState(null);
  const [minutes, setMinutes] = useState('');
  const alarmSoundRef = useRef(null);
  const [playSound, setPlaySound] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    let duration;
    const currentTime = moment().local();

    if (time) {
      
        setSubmitted(true);
        
      const eventTime = moment(time, 'hh:mm A');
      duration = moment.duration(eventTime.diff(currentTime)).asMilliseconds();
      
      if(duration < 0){
        const eventTime = moment(time, 'hh:mm A').add(1, 'day');
        duration = moment.duration(eventTime.diff(currentTime)).asMilliseconds();
      }

    } 
    
    if (minutes) {
        setSubmitted(true);
        console.log('minutes submitted is ', submitted);
      duration = moment.duration(parseInt(minutes, 10), 'minutes').asMilliseconds();
    }


    if (duration > 0) {
        setTimer(duration);
      }
  };

  useEffect(() => {
    
    if (timer && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1000);
      }, 1000);

      return () => clearInterval(interval);
    }

    if (timer <= 0 && playSound && alarmSoundRef.current) {
      alarmSoundRef.current.play();
    }
    
  }, [timer, playSound]);

  return (
    <div className="App">
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <input
              type="text"
              placeholder="Meeting Name (optional)"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              maxLength="100"
            />
        
            <input
              type="text"
              placeholder="Starting in text(optional)"
              value={backIn}
              onChange={(e) => setBackIn(e.target.value)}
              pattern="[A-Za-z\s\-]*"
              maxLength="100"
              title="Please use only alphabets, spaces, or hyphens."
            />
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="play-sound-toggle" style={{ color: 'white' }}>Play sound at end   </label>
              <input
                type="checkbox"
                id="play-sound-toggle"
                checked={playSound}
                onChange={(e) => setPlaySound(e.target.checked)}
              />
    </div>

    <br/>
            <input
  type="text"
  placeholder="Minutes"
  pattern="^([1-9][0-9]*|0[1-9][0-9]*)$"
  title="Only positive integers allowed. Leading zeros are not allowed."
  value={minutes}
  onChange={(e) => {
    if (e.target.validity.valid || e.target.value === "") {
      setMinutes(e.target.value);
    }
  }}
/>


          <span style={{ fontSize: "3vw" , color: "white" }}>OR</span>
            <input
  type="text"
  placeholder="H:mmAM/PM"
  value={time}
  onChange={(e) => setTime(e.target.value)}
  pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s*([AaPp][Mm])$"
  title="Please enter a valid time format (H:mmAM/PM or H:mm PM/AM)."
/>

        {}
                   <br/>
          <button type="submit">Submit</button>
        </form>
      ) : (
        <>
        
    <audio ref={alarmSoundRef} src={alarmSound} preload="auto"></audio>
          <h1 className="event-name">{eventName || ''}</h1>
          <p className="timer-text">
  {timer > 0 ? (
    <>
    <div
  style={{
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
    fontFamily: "'Nunito Sans', sans-serif",
  }}
>
  <span style={{ fontSize: "5vw" }}>
    {backIn || ' Starting in  '}
  </span>
  <span
    style={{
      fontSize: "8vw",
      fontWeight: "bold",
      color: timer < 60000 ? "orange" : "inherit",
      minWidth: "25vw",
      textAlign: "center",
    }}
  >
    {`${Math.floor(timer / 3600000) > 0 ? `${Math.floor(timer / 3600000)
      .toString()
      .padStart(2, "0")}:` : ""}${Math.floor((timer % 3600000) / 60000)
      .toString()
      .padStart(2, "0")}:${Math.floor(((timer % 3600000) % 60000) / 1000)
      .toString()
      .padStart(2, "0")}`}
  </span>
</div>




      
    </>
  ) : (
    eventName ? '' : <span style={{ fontSize: "8vw" , fontWeight: "bold" }}>00:00</span>
  )}
</p>


        </>
      )}
    </div>
  );
}

export default App;

